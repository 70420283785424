@import "../../styles/vars.scss";

.navbar {
    display: none;
}

@media screen and (min-width: 768px) {
    .navbar {
        position: fixed;
        top: 0;
        height: 4rem;
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: space-evenly;

    }

    .center {
        display: none;
    }

    .open .center {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        align-content: center;
        animation: slide-in 0.5s;
    }

}

.toggle {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 32px;
    justify-content: center;
    position: absolute;
    right: 16px;
    width: 32px;
    z-index: 2;
}

.toggle::before,
.toggle::after {
    box-shadow: 0 1px 0 0 var(--textColor), 0 -2px 0 0 var(--textColor);
    content: ' ';
    display: block;
    height: 4px;
    transition: .15s all ease-in-out;
    width: 18px;
}

.toggle::after {
    box-shadow: 0 2px 0 0 var(--textColor), 0 -1px 0 0 var(--textColor);
}

.open .toggle {
    align-items: center;
    display: flex;
    justify-content: center;
}

.open .toggle::before,
.open .toggle::after {
    box-shadow: 0 0 0 1px var(--textColor);
    height: 0;
    position: absolute;
    transform: rotate(45deg);
    width: 13px;
}

.open .toggle::after {
    transform: rotate(-45deg);
}

@keyframes slide-in {
     from {
         margin-left: 100%;
     }

     to {
         margin-left: 0;
     }
 }
/*TODO add slide out animation*/