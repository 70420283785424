.skills {
 display: flex;
 justify-content: center;
 align-items: center;
}

.title {
 display: flex;
 justify-content: center;
 align-items: center;
}