@import "../../styles/vars.scss";

.item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.item img {
    margin: 2em 0;
    max-width: 100%;
    min-height: 20vh;
    max-height: 50vh;
}

.top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 1000px) {
    .item {
        flex-direction: row;
    }

    .item img {
        max-width: 50%;
        min-height: 30vh;
    }

    .top {
        align-items: flex-start;
        margin-right: 2em;
    }
}

.item a {
    text-decoration: underline;
    color: var(--textColor);
}

.description {
    padding-bottom: 1rem;
}

