@import url('https://fonts.googleapis.com/css?family=Montserrat:400|Inconsolata:400');
@import 'vars.scss';

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

html {
  font-size: var(--defaultFontSize);
}
@media only screen and (min-width: 576px) {
  :root {
    --defaultFontSize: 100%;
  }
}
@media only screen and (min-width: 768px) {
  :root {
    --defaultFontSize: 112.5%;
  }
}

body {
  background: var(--backgroundColor);
  color: var(--textColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  overflow: hidden;
}


h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: var(--letterSpacing);
}

h1 {
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text_small {
  font-size: var(--smallText);
}

ul {
  padding: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
}

#root{
  max-width: 100vw;
}

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;
// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
