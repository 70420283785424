.page {
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;

    scroll-snap-align: start;
    padding: 0 10%;
}

.page > div {
    width: 100%;
}

@media (min-width: 800px) {
    .page  > div {
        width: 80%;
    }
}

.hide {
    display: none;
}

.show {
    display: flex;
}
