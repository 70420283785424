$purple: null;
$red: null;
$orange: null;
$border-radius: null;
$text-color:null;


:root {
  /* COLORS  */
  /* primary */
  --primary-0: #f0efff;
  --primary-50: #d8d6ff;
  --primary-100: #c1beff;
  --primary-200: #aaa5ff;
  --primary-300: #938dff;
  --primary-400: #7b74ff;
  /* main */
  --primary-500: #645cff;
  --primary-600: #554ed9;
  --primary-700: #4640b3;
  --primary-800: #37338c;
  --primary-900: #282566;
  --primary-1000: #191740;
  --primary-1100: #0a0919;
  /* grey */
  --grey-0: #fafbfc;
  --grey-50: #f2f4f8;;
  --grey-100: #eaedf3;
  --grey-200: #e2e7ef;
  --grey-300: #dbe0ea;
  --grey-400: #d3dae6;
  --grey-500: #cbd3e1;
  --grey-600: #adb3bf;
  --grey-700: #8e949e;
  --grey-800: #70747c;
  --grey-900: #51545a;
  --grey-1000: #333538;
  --grey-1100: #141516;
  --color-dark-gray: #333538;



  /* typography */
  --defaultFontSize: 87.5%;
  --headingFont: 'Inconsolata', monospace;
  --bodyFont: 'Montserrat', sans-serif;
  --smallText: 0.8em;
  --extraSmallText: 0.7em;
  /* rest */
  --backgroundColor: var(white);
  --textColor: var(--grey-1000);
  --letterSpacing: 1px;


  /* colors */
  $purple:  rgba(131, 58, 180, 1) !global;
  $red: rgba(253, 29, 29, 1) !global;
  $orange: rgba(252, 176, 69, 1) !global;


  $border-radius: 0.5rem !global;
  $text-color:  #51545a !global;
}
