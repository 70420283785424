@import "../../styles/vars.scss";

.footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    scroll-snap-align: end;

    border-top: var(--grey-700) solid 1px;
    margin: 0 10%;
    padding: 3% 0;
}

.copyright{
    font-size: var(--smallText);
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1% 0;
    scroll-snap-align: end;
}
@media (min-width: 400px) {
    .footer {
        flex-direction: row;
    }
}

.background{
    width: 100%;
    background: linear-gradient(8deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 6%, rgba(252, 176, 69, 1) 12%, rgba(252, 252, 252, 1) 22%, rgba(255, 255, 255, 1) 100%);

}